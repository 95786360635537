<template>
    <div class="page-table mainDiv">
        <div class="card-base baslikBg animated fadeInUp">
            {{$t("src.views.apps.iletisim.ayar.islem.baslikH1")}}
        </div>
        <el-row :gutter="10">
            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                <el-table
                        :data="ayarListesi"
                        style="width: 100%">
                    <el-table-column
                            prop="ayarAdi"
                            label="Başlık"
                            width="auto">
                    </el-table-column>
                    <el-table-column
                            prop="mailDetay"
                            label="Mail"
                            width="auto">
                    </el-table-column>
                    <el-table-column
                            prop="smsDetay"
                            label="SMS"
                            width="auto">
                    </el-table-column>
                    <el-table-column
                            :label='$t("src.views.apps.genel.islem")'
                            align="right"
                            width="auto">
                        <template slot-scope="scope">
                            <el-tooltip :content='$t("src.views.apps.genel.guncelle")' :open-delay="500"
                                        placement="bottom">
                                <el-button
                                        v-on:click="selectionIndex=scope.$index; selection=scope.row; updateStart(scope.row); "
                                        type="warning"
                                        size="mini"
                                        icon="el-icon-edit">
                                </el-button>
                            </el-tooltip>
                            <!--<el-tooltip :content='$t("src.views.apps.iletisim.ayar.islem.sil")' :open-delay="500"-->
                            <!--placement="bottom">-->
                            <!--<el-button-->
                            <!--v-on:click="dialogVisible = true; selection=scope.row; selectionIndex=scope.$index"-->
                            <!--size="mini"-->
                            <!--type="danger"-->
                            <!--icon="mdi mdi-delete">-->
                            <!--</el-button>-->
                            <!--</el-tooltip>-->
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
            <el-col :lg="6" :md="6" :sm="24" :xs="24">
                <div class="card-base card-shadow--medium demo-box bg-white animated fadeInUp">
                    <el-form status-icon :model="ayarForm" :rules="rulesRakam" ref="ayarForm" class="demo-ruleForm">
                        <el-row>
                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                <el-form-item :label='$t("src.views.apps.iletisim.ayar.islem.ayarAdi")'
                                              prop="ayarAdi">:
                                    <el-input size="small"  v-model="ayarForm.ayarAdi" :disabled="!btnVisible"
                                              :placeholder='$t("src.views.apps.iletisim.ayar.islem.ayarAdiPlace")'></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                <el-form-item :label='$t("src.views.apps.iletisim.ayar.islem.mail")' prop="mail">:
                                    <el-switch
                                            v-model="ayarForm.mail"
                                            active-value="1"
                                            inactive-value="0"
                                            active-text="Aç"
                                            inactive-text="Kapat">
                                    </el-switch>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                <el-form-item :label='$t("src.views.apps.iletisim.ayar.islem.sms")' prop="sms">:
                                    <el-switch
                                            v-model="ayarForm.sms"
                                            active-value="1"
                                            inactive-value="0"
                                            active-text="Aç"
                                            inactive-text="Kapat">
                                    </el-switch>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row class="col-p">
                            <el-form-item>
                                <el-button v-if="btnVisible" size="small" v-on:click="ayarTanimla('ayarForm')"
                                           type="primary">
                                    {{$t('src.views.apps.genel.ekle')}}
                                </el-button>
                                <el-button v-else size="small" v-on:click="ayarGuncelle('ayarForm')" type="primary">
                                    {{$t('src.views.apps.genel.guncelle')}}
                                </el-button>
                                <el-button v-if="!btnVisible" size="small" v-on:click="vazgec('ayarForm')">
                                    {{$t('src.views.apps.genel.vazgec')}}
                                </el-button>
                            </el-form-item>
                        </el-row>
                    </el-form>
                </div>
            </el-col>
        </el-row>

        <!--<el-dialog-->
                <!--:title='$t("src.views.apps.haberler.islem.uyari")'-->
                <!--:visible.sync="dialogVisible"-->
                <!--width="30%"-->
                <!--center>-->
            <!--<span>"{{selection["baslik"]}}" {{$t('src.views.apps.iletisim.ayar.islem.sil')}}</span>-->
            <!--<span slot="footer" class="dialog-footer">-->
                    <!--<el-button icon="el-icon-check"-->
                               <!--type="success"-->
                               <!--size="mini"-->
                               <!--@click="sil(selection,selectionIndex,ayarListesi), dialogVisible = false"></el-button>-->
                    <!--<el-button size="mini" type="danger" icon="el-icon-close"-->
                               <!--@click="dialogVisible = false"></el-button>-->
                <!--</span>-->
        <!--</el-dialog>-->
    </div>
</template>

<script>

    import iletisim from '../../../../WSProvider/IletisimService'
    // import status from '../../../../status'
    import JQuery from 'jquery';

    let $ = JQuery;
    let base64 = require('base64_js');
    let userDataBGSurec = "";
    export default {
        name: "AyarIslem",
        mounted() {
            let state = false;
            if (localStorage.getItem("userDataBGSurec")) {
                userDataBGSurec = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBGSurec"))));
                if (userDataBGSurec["tur"]) {
                    if (userDataBGSurec["tur"] === '1' || userDataBGSurec["tur"] === '2')
                        state = true;
                }
            }
            if (!state) {
                this.$store.commit('setLogout');
                this.$router.push("/login");
            } else {
                this.getAyarlarList();
            }
        },
        data() {
            return {
                ayarListesi: [],
                ayarKodu: '',
                btnVisible: true,
                selection: '',
                selectionIndex: '',
                dialogVisible: false,

                sizes: 10,
                page: 0,
                postPage: 0,
                total: null,

                ayarForm: {
                    ayarAdi: '',
                    sms: '1',
                    mail: '1',
                },
                rulesRakam: {
                    ayarAdi: [{
                        required: true,
                        message: "Lütfen Rakam Adı Giriniz",
                        trigger: 'blur'
                    }],
                    sayi: [{
                        required: true,
                        message: "Lütfen Sayı Giriniz",
                        trigger: 'blur'
                    }],
                },
            }
        },
        methods: {
            pageChange(val) {
                this.postPage = (val - 1) * this.sizes;
                this.getAyarlarList();
            },
            handleSizeChange(val) {
                this.sizes = val;
                this.getAyarlarList();
            },
            getAyarlarList() {
                iletisim.iletisimAyarList().then((response) => {
                    if (response.status === 200) {
                        response.data.forEach(function (e) {
                           if(e.mail === '1'){
                               e.mailDetay = "Bildirim Açık"
                           }else{
                               e.mailDetay = "Bildirim Kapalı"
                           }
                            if(e.sms === '1'){
                                e.smsDetay = "Bildirim Açık"
                            }else{
                                e.smsDetay = "Bildirim Kapalı"
                            }
                        });
                        this.ayarListesi = response.data;
                    }else{
                        status.Status(response.status,this)
                    }
                });
            },
            updateStart(scope) {
                this.ayarForm.ayarAdi = scope.ayarAdi;
                this.ayarForm.mail = scope.mail;
                this.ayarForm.sms = scope.sms;
                this.ayarKodu = scope.ayarKodu;
                this.btnVisible = false;
            },
            vazgec(formName) {
                this.$refs[formName].resetFields();
                this.btnVisible = true;
            },
            ayarGuncelle(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        iletisim.iletisimAyarGuncelle(this.ayarKodu, this.ayarForm.mail, this.ayarForm.sms).then((response) => {
                            switch (response.status) {
                                case 200:
                                    status.Status('success', this, 'Program başarıyla güncellendi...');
                                    switch (response.data.sms) {
                                        case '0':
                                            response.data.smsDetay = 'Bildirim Kapalı';
                                            break;
                                        case '1':
                                            response.data.smsDetay = 'Bildirim Açık';
                                            break;
                                    }
                                    switch (response.data.mail) {
                                        case '0':
                                            response.data.mailDetay = 'Bildirim Kapalı';
                                            break;
                                        case '1':
                                            response.data.mailDetay = 'Bildirim Açık';
                                            break;
                                    }
                                    this.ayarListesi.splice(this.selectionIndex, 1);
                                    this.ayarListesi.splice(this.selectionIndex, 0, {
                                        ayarAdi: response.data.ayarAdi,
                                        smsDetay: response.data.smsDetay,
                                        sms: response.data.sms,
                                        mail: response.data.mail,
                                        mailDetay: response.data.mailDetay,
                                        ayarKodu: response.data.ayarKodu
                                    });
                                    this.$refs[formName].resetFields();
                                    this.btnVisible = true;
                                    break;
                            }
                        });
                    }
                })
            },
            // sil(selection, index, list) {
            //     iletisim.kaldir(selection.hkId).then((response) => {
            //         switch (response.status) {
            //             case 200:
            //                 status.Status('success', this, 'Kategori başarıyla silindi.');
            //                 list.splice(index, 1);
            //                 break;
            //             case 150:
            //                 status.Status('info', this);
            //                 break;
            //             case 400:
            //                 status.Status('error400', this);
            //                 break;
            //             case 503:
            //                 status.Status('error', this);
            //                 break;
            //         }
            //     })
            // },
            ayarTanimla(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        iletisim.iletisimAyarTanimlama(this.ayarForm.ayarAdi, this.ayarForm.mail,this.ayarForm.sms).then((response) => {
                            if (response.status === 200) {
                                status.Status('success', this, 'Ayar başarıyla eklendi.');
                                switch (response.data.sms) {
                                    case '0':
                                        response.data.smsDetay = 'Bildirim Kapalı';
                                        break;
                                    case '1':
                                        response.data.smsDetay = 'Bildirim Açık';
                                        // window.location.reload();
                                        break;
                                }
                                switch (response.data.mail) {
                                    case '0':
                                        response.data.mailDetay = 'Bildirim Kapalı';
                                        break;
                                    case '1':
                                        response.data.mailDetay = 'Bildirim Açık';
                                        // window.location.reload();
                                        break;
                                }
                                this.ayarListesi.push(response.data);
                                this.$refs[formName].resetFields();
                            } else if (response.status === 230) {
                                status.Status('warning', this, 'Bu bilgiler ile kayıtlı bir ayar zaten mevcut');
                            } else {
                                status.Status(response.status, this)
                            }
                        });
                    }
                })
            },
        }
    }

</script>
<style lang="scss" scoped>
    .el-pagination {
        margin-top: 20px;
        float: right;
    }

    .kaldirilmis {
        background: #ec205f;
        color: white;
        font-weight: bold;
        font-size: 16px;
        padding: 10px
    }
</style>

<style scoped>

</style>